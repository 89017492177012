<template>

  <section class="badges">
    <div class="row">
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Normal Badges</h4>
            <p class="card-description">Use <code>&lt;b-badge&gt;</code> for basic badge</p>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Default label</td>
                    <td class="pr-0 text-right"><b-badge variant="primary">Primary</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Primary label</td>
                    <td class="pr-0 text-right"><b-badge variant="info">Info</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Danger label</td>
                    <td class="pr-0 text-right"><b-badge variant="danger">Danger</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Success label</td>
                    <td class="pr-0 text-right"><b-badge variant="success">Success</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Warning label</td>
                    <td class="pr-0 text-right"><b-badge variant="warning">Warning</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Badges Pills</h4>
            <p class="card-description">Use <code>pill</code> prop rounded badge</p>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Default label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="primary">Primary</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Primary label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="info">Info</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Danger label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="danger">Danger</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Success label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="success">Success</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Warning label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="warning">Warning</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Normal Badges</h4>
            <p class="card-description">Use <code> variant="outline-*" </code> for outlined style</p>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Default label</td>
                    <td class="pr-0 text-right"><b-badge variant="outline-primary">Primary</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Primary label</td>
                    <td class="pr-0 text-right"><b-badge variant="outline-info">Info</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Danger label</td>
                    <td class="pr-0 text-right"><b-badge variant="outline-danger">Danger</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Success label</td>
                    <td class="pr-0 text-right"><b-badge variant="outline-success">Success</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Warning label</td>
                    <td class="pr-0 text-right"><b-badge variant="outline-warning">Warning</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Rounded Outlined Badges</h4>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Default label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-primary">2</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Primary label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-info">5</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Danger label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-danger">1</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Success label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-success">9</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Warning label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-warning">4</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Rounded Variation</h4>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Default label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="primary">2</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Primary label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="info">5</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Danger label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="danger">1</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Success label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="success">9</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Warning label</td>
                    <td class="pr-0 text-right"><b-badge pill variant="warning">4</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Badge With Icons</h4>
            <div class="template-demo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="pl-0">Item</th>
                    <th class="text-right">Label</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pl-0">Icon Badge</td>
                    <td class="pr-0 text-right"><b-badge pill variant="primary"><i class="icon-user"></i></b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Outlined Icons</td>
                    <td class="pr-0 text-right"><b-badge pill variant="outline-primary"><i class="icon-cloud-download"></i></b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Icon With Text</td>
                    <td class="pr-0 text-right"><b-badge pill variant="danger"><i class="icon-envelope mr-2"></i>12</b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Icon With Text</td>
                    <td class="pr-0 text-right"><b-badge pill variant="success">12<i class="icon-envelope ml-2"></i></b-badge></td>
                  </tr>
                  <tr>
                    <td class="pl-0">Icon Badge</td>
                    <td class="pr-0 text-right"><b-badge pill variant="warning"><i class="icon-speech"></i></b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'badges'
}
</script>